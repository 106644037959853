import { UnknownIcon } from '@/misc/CustomMarkers';

export const defaultMapData = {
    lat: Number(process.env.VUE_APP_LAT),
    lon: Number(process.env.VUE_APP_LON),
    center: [Number(process.env.VUE_APP_LAT), Number(process.env.VUE_APP_LON)],
    markerLatLng: [
        Number(process.env.VUE_APP_LAT),
        Number(process.env.VUE_APP_LON),
    ],
    url: process.env.VUE_APP_OPENSTREETMAP_URL!,
    map_attribution: process.env.VUE_APP_ATTRIBUTION!,
    zoom: Number(process.env.VUE_APP_MAP_ZOOM!),
    zoomMax: Number(process.env.VUE_APP_MAP_ZOOM_MAX!),
    geoURL: process.env.VUE_APP_GEO_URL_REVERSE!,
    format: process.env.VUE_APP_FORMAT!,
    icon: UnknownIcon,
};
