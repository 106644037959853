






























































































import {Component, Prop, Watch} from 'vue-property-decorator';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import {LCircle, LMap, LMarker, LTileLayer} from 'vue2-leaflet';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {defaultMapData} from '@/helper/DeafultMapData';
import {CountryStorage} from '@/misc/CountryStorage';
import {validationMixin} from 'vuelidate';
import {maxLength, numeric, required} from 'vuelidate/lib/validators';
import MapRepository from '@/api/repositories/MapRepository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import Address from '@/models/Address';

interface AddressEvent {
  address: {
    road: string;
    city: string;
    town: string;
    suburb: string;
    village: string;
    postcode: string;
    house_number: string;
    country: string;
  };
  lat: number;
  lon: number;
}

const mapRepository: MapRepository = RepositoryFactory.get('map');

@Component({
  components: {
    RJSelect, RJTextField,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
  },
  mixins: [validationMixin],
  validations: {
    address: {
      street: {required},
      houseNo: {required, maxLength: maxLength(15)},
      postalCode: {required, numeric, maxLength: maxLength(5)},
      city: {required},
      country: {required},
    },
  },
})

export default class AddressWithMapComponent extends mixins(ErrorMessageHandlerMixin) {

  @Prop({default: null, required: false})
  private addressOfObject!: Address;

  @Prop({default: false, required: false})
  private blocked!: boolean;

  /**
   * Default data for map
   * @private
   */
  private mapData = defaultMapData;

  /**
   * Countries for country select
   */
  public get countries(): Array<{ text: string, value: string }> {
    return CountryStorage;
  }

  public geoPosition?: { lat: number, lng: number };
  /**
   * suggestions for addresses in the Street combobox
   */
  private addressItems: any[] = [];

  /**
   * is true when loading data from OpenStreetMaps
   */
  private isLoading: boolean = false;
  /**
   * the String in the Address street Combobox for the OSM suggestions
   */
  private searchString: string = '';

  public address: Address = new Address();

  public async created() {
    await this.checkChangeAddress();
  }

  @Watch('addressOfObject')
  private async checkChangeAddress() {
    if (this.addressOfObject) {
      this.address = this.addressOfObject;
      this.searchString = this.addressOfObject.street!;
      if (this.addressOfObject.geoPosition) {
        this.setMarker(this.addressOfObject.geoPosition.lat, this.addressOfObject.geoPosition.lng);
      }
      this.$v.address.$reset();
    }
  }

  @Watch('address', {deep: true, immediate: true})
  private input() {
    this.$emit('input', {
      address: this.address,
      vuelidate: this.$v,
    });
  }

  /**
   * Map callback
   * @param event
   * @private
   */
  private async addMarker(event: {
    latlng: {
      lat: number;
      lng: number;
    },
  }) {
    // don't change the marker while blocked
    if (this.blocked) {
      return;
    }
    this.setMarker(event.latlng.lat, event.latlng.lng);
    await this.addressByCoordinates(event.latlng.lat, event.latlng.lng);
  }

  /**
   * Reverse geocoding.
   * Updates address object on success.
   * @param lat
   * @param lon
   */
  private async addressByCoordinates(lat: number, lon: number): Promise<void> {
    try {
      this.isLoading = true;
      const {data} = await mapRepository.getAddressByCoordinates(lat, lon);
      await this.setAddress(data);
    } catch (e: any) {
      this.$notifyErrorSimplified('LOCATION_MANAGE.NOTIFICATIONS.LOADING_MAP_ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * This function is called when the user changes the location Address via the combobox suggestions.
   * Changes the Address and updated the geolocation for the map
   * @param event the selected input from the combobox
   */
  public async searchInput(event: any) {
    if (typeof event === 'object') {
      this.skipLoad = true;
      // set address to location
      await this.setAddress(event);
      this.addressItems = [];
      this.setMarker(event.lat, event.lon);
    }
  }

  private setMarker(lat: number, lon: number) {
    this.mapData.markerLatLng = [lat, lon];
    this.mapData.lat = Number(lat);
    this.mapData.lon = Number(lon);
    if (this.mapData.lat && this.mapData.lon) {
      this.mapData.center = [this.mapData.lat, this.mapData.lon];
    }
  }

  /**
   * Searching the street, returning the address
   * @param street
   */
  private async addressByStreet(street: string): Promise<void> {
    if (!street) {
      return;
    }
    // Start loading
    this.isLoading = true;

    try {
      const response = await mapRepository.getAddress(street);
      this.addressItems = response.data;
    } catch (e: any) {
      this.$notifyErrorSimplified('LOCATION_MANAGE.NOTIFICATIONS.LOADING_MAP_ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  public getCity(address: AddressEvent['address']): string {
    if (address.city) {
      return address.city;
    } else if (address.town) {
      return address.town;
    } else if (address.village) {
      return address.village;
    } else {
      return address.suburb;
    }
  }

  /**
   * flag to skip the Load , which normally happens when changing the searchstring
   */
  private skipLoad: boolean = true;

  private searchBarDebounce: number = 0;

  /**
   * Checking the user search input in the street field
   */
  @Watch('searchString')
  private async onSearchStreetChanged() {
    this.address.street = this.searchString;
    if (!this.skipLoad) {
      window.clearTimeout(this.searchBarDebounce);
      this.searchBarDebounce = window.setTimeout(async () => {
        await this.addressByStreet(this.searchString);
      }, 600);
    }
    this.skipLoad = false;
  }

  public setAddress(event: AddressEvent) {
    this.searchString = event.address.road;
    this.address.street = event.address.road;
    this.address.postalCode = event.address.postcode;
    this.address.houseNo = event.address.house_number;
    this.address.city = this.getCity(event.address);
    this.address.geoPosition = {lat: event.lat, lng: event.lon};
    this.address.country = this.countries.find((country) => country.text.toLowerCase() === event.address.country.toLowerCase())?.value ?? '';
    this.$v.$reset();
    this.$emit('Filtered', this.address);
  }
}

