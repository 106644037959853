import i18n from '@/i18n';

export const CountryStorage: Array<{ text: string, value: string }> = [{
    text: i18n.t('GENERAL.COUNTRIES.GERMANY').toString(),
    value: 'germany',
}, {
    text: i18n.t('GENERAL.COUNTRIES.AUSTRIA').toString(),
    value: 'austria',
}];

export const CountryFlags = {
    germany: '🇩🇪',
    austria: '🇦🇹',
};
