import L from 'leaflet';

export const UnknownIcon = L.icon({
    iconUrl: require('../assets/images/map/map-marker-default.png'),
    shadowSize: [42, 37],
    shadowAnchor: [18, 36],
    iconSize: [40, 35],
    iconAnchor: [20, 35],

});
